import React from "react";
import {useState} from "react";
import "../cardplan/cardplan.css";
import "primeicons/primeicons.css";
const Cardplan = () => {

  const [planDuration, setPlanDuration] = useState("monthly");
  

  const handlePlanDurationChange = (duration) => {
    setPlanDuration(duration);
   
  };
  return (
    <div className="cardplanMain">
      <div className="cardplanheader">Unlock With Premium</div>
      <div className="cardplanbluelinemark">
        <div className="cardplanblueline"></div>
      </div>
      <div className="cardplanheader2" style={{ padding: "0px 10px" }}>
      Pick the plan that's best for you or {" "}
        <span>
          <a
            href="tel:+91-8824693997"
            style={{
              color: "#212E61",
              textDecoration: "underline",
              textDecorationColor: "#212E61",
            }}
          >
            call us
          </a>
          &nbsp;
        </span>
        to find it.
      </div>

      <div className="cardplanTime">
        <div className="cardplanMonthly"><button onClick={() => handlePlanDurationChange("monthly" )} style={{borderStyle:"none", backgroundColor:"transparent", cursor:"pointer",color:planDuration === "monthly"? "#FF6C2C" : "#212E61"}} className="cardplanMonthly">{planDuration === "monthly"?<span style={{ textDecoration:"underline",
   textUnderlineOffset:"15px"}}>Monthly</span>:"Monthly"}</button></div>
        {/* <div className="cardplanHalfYearly"><button onClick={() => handlePlanDurationChange("halfYearly")} style={{borderStyle:"none", backgroundColor:"transparent",cursor:"pointer",color:planDuration === "halfYearly"? "#FF6C2C" : "#212E61"}} className="cardplanHalfYearly">{planDuration === "halfYearly" ? <span style={{ textDecoration:"underline" , textUnderlineOffset:"15px"}}>Half Yearly</span>:"Half Yearly"}</button></div> */}
        <div className="cardplanYearly"><button onClick={() => handlePlanDurationChange("yearly")} style={{borderStyle:"none", backgroundColor:"transparent",cursor:"pointer",color:planDuration === "yearly"? "#FF6C2C" : "#212E61"}} className="cardplanYearly">{planDuration === "yearly" ? <span style={{ textDecoration:"underline" , textUnderlineOffset:"15px"}}> Yearly</span>:"Yearly"}</button></div>
      </div>
      <div className="cardplansection">
        <div className="cardplansection1">
          <p className="basicplanhead"> Basic Plan</p>

          <div className="basicplanContent">
            <div className="basicplanprice">{planDuration==="monthly"? "Free" : planDuration==="halfYearly" ? "Free"  : "Free" }</div>
            <div className="basicplandescription">
              Get started with our Basic Plan Kickstart your virtual journey 
            </div>
            <div className="basicplanbluemark">
              <div className="basicplanblueline"></div>
            </div>
            <div className="basicplanAddressVehicle">
              <div className="basicplanaddresss">
                <i className="pi pi-check">&nbsp;
              </i>
              <span> Number of QR Codes - 3</span>
              </div>
              <div className="basicplanmember">
                <i className="pi pi-check">&nbsp;</i>
                <span> Number of  Calls per day - 3</span>
              </div>
              <div className="basicplandownload">
                <i className="pi pi-check">
              &nbsp;
                </i>
              <span>Call Duration (in seconds) - 30</span>
              </div>
              <div className="basicplandiscount">
                <i className="pi pi-check">&nbsp;</i>
                <span>Notification Alert</span>
              </div>
              
              <div className="basicplangeneration">
                <i className="pi pi-check">&nbsp;</i>
                <span>Call Logs  - 1 Week</span>
              </div>
             
           
              
            </div>
          </div>
          <div className="basicplanpremium">
            {/* <button className="basicplanpremiumbutton">Go Premium</button> */}
          </div>
        </div>

        {/* 2nd card */}
        <div className="cardplansection2">
          <p className="premiumplanhead">
            {" "}
            Standard Plan &nbsp;&nbsp;
            <span className="premiumplanpopular">POPULAR</span>
          </p>

          <div className="premiumplanContent">
            <div className="premiumplanprice">₹{" "}{planDuration==="monthly"? "49.00/month" : planDuration==="halfYearly" ? "299.00" : "299.00/year" }</div>
            <div className="premiumplandescription">
              Standard Plan for a comprehensive virtual experience
            </div>
            <div className="premiumplanbluemark">
              <div className="basicplanblueline"></div>
            </div>
            <div className="premiumplanAddressVehicle">
              <div className="premiumplanaddresss">
                <i className="pi pi-check">
                &nbsp;
                </i>
                <span>Number of QR Codes - 10</span>
              </div>
              <div className="premiumplanvehicle">
                <i className="pi pi-check">
                &nbsp;
                </i><span>Number of Members - 2</span>
              </div>
              <div className="premiumplanmember">
                <i className="pi pi-check">&nbsp;
                
                </i><span>Number of  Calls per day - Infinite</span>
              </div>
              <div className="premiumplandownload">
                <i className="pi pi-check">&nbsp;
                
                </i><span>Call Duration (in seconds) - 60</span>
              </div>
              <div className="premiumplandiscount">
                <i className="pi pi-check">&nbsp;
                </i><span>Notification Alert</span>
              </div>
            
              <div className="premiumplancall">
                <i className="pi pi-check">&nbsp;</i><span>Call Logs - 1 Month</span>
              </div>
             
           
              <div className="premiumplancall">
                <i className="pi pi-check">&nbsp;</i><span>Block User Functionality</span>
              </div>

              <div className="premiumplandiscount">
                <i className="pi pi-check">&nbsp;
                </i><span>Customer Care via E-mail (Response Time- 24 hours)</span>
              </div>
              
            </div>
          </div>
          <div className="premiumplanpremium">
            <button className="premiumplanpremiumbutton">Go Premium</button>
          </div>
        </div>

        {/* 3rd card */}
        <div className="cardplansection3">
          <p className="vipplanhead"> Premium Plan </p>

          <div className="vipplanContent">
            <div className="vipplanprice">₹{" "}{planDuration==="monthly"? "99.00/month" : planDuration==="halfYearly" ? "399.00" : "499.00/year" }</div>
            <div className="vipplandescription">
            Take your virtuality to the next level with unlimited benefits
            </div>
            <div className="vipplanbluemark">
              <div className="basicplanblueline"></div>
            </div>
            <div className="vipplanAddressVehicle">
              <div className="vipplanaddresss">
                <i className="pi pi-check">&nbsp;
                </i><span>Number of QR Codes - Infinite</span>
              </div>
              <div className="vipplanvehicle">
                <i className="pi pi-check">&nbsp;
                
                </i><span>Number of Members - Infinite</span>
              </div>
              <div className="vipplanmember">
                <i className="pi pi-check">&nbsp;
                
                </i><span>Number of  Calls per day - Infinite</span>
              </div>
              <div className="vipplandownload">
                <i className="pi pi-check">&nbsp;
                
                </i><span>Call Duration (in seconds) - 120</span>
              </div>
              <div className="vipplandiscount">
                <i className="pi pi-check">&nbsp;
                
                </i><span>Notification Alert</span>
              </div>
             
              <div className="vipplancall">
                <i className="pi pi-check">&nbsp;</i><span>Call Logs - Infinite</span>
              </div>
              <div className="vipplancall">
                <i className="pi pi-check">&nbsp;</i><span>Block User Functionality</span>
              </div>
              <div className="vipplancall">
                <i className="pi pi-check">&nbsp;</i><span>Visitor Capture</span>
              </div>
             
             
              <div className="vipplancall">
                <i className="pi pi-check">&nbsp;</i><span>Conference Call</span>
              </div>
              <div className="vipplancall">
                <i className="pi pi-check">&nbsp;</i><span>Voice Recording</span>
              </div>
              <div className="vipplancall">
                <i className="pi pi-check">&nbsp;
                </i><span>Customer Care via E-mail (Response Time- 4 hours, Dedicated Relationship Manager)</span>
              </div>
          
            </div>
          </div>
          <div className="vipplanpremium">
            <button className="vipplanpremiumbutton">Go Premium</button>
          </div>
        </div>
    
      </div>
    </div>
  );
};

export default Cardplan;
