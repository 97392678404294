import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../features/features.css";
import { useNavigate } from "react-router-dom";

const personScanningqr =
  process.env.PUBLIC_URL + "/images/Website Images/QR Scanning in Features.png";
const youngmale =
  process.env.PUBLIC_URL + "/images/Website Images/WhoosiT Video Calling 1.png";
const sideviewwoman =
  process.env.PUBLIC_URL + "/images/side-view-woman-holding-smartphone@2x.png";
const bussinessapplication =
  process.env.PUBLIC_URL +
  "/images/businessman-application-human-digital-business@2x.png";
const mediumshotwoman =
  process.env.PUBLIC_URL +
  "/images/medium-shot-woman-communicating-with-laptop@2x.png";
const businesspeople =
  process.env.PUBLIC_URL + "/images/business-people-using-their-phones@2x.png";
const peopleworking =
  process.env.PUBLIC_URL +
  "/images/person-working-with-floating-screens@2x.png";



  const arrowleft = process.env.PUBLIC_URL + "/images/Group 63@3x.png";
const arrowright = process.env.PUBLIC_URL + "/images/Group 63@3x1.png";

const MultiItemCarousel = () => {

    const navigate = useNavigate();

  const handleClick = () => {
    navigate('/plan');
  };
  const CustomPrevArrow = ({ onClick }) => (
    <button className="custom-arrow-feature previousfeature" onClick={onClick}>
      <img src={arrowright} className="arrowleftfeature"/>
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button className="custom-arrow-feature nextfeature" onClick={onClick}>
 <img src={arrowleft} className="arrowrightfeature"/>
    </button>
  );
  
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
    ],
  };

  return (
    <div className="features">
    <h3 className="featureshead">Features</h3>
    <div className="featuresline"><div className="featuresbluelines"></div></div>
    <p className="featuresContentA">With WhoosiT</p>
    <p className="featuresContentB">Leave the obtrusive doorbells behind.</p>
    <div className="carousel-outer-container">
    <div className="carousel-container">
      <Slider {...settings} className="featureSlider" >


        <div className="sliderimagesperson">
          <img src={personScanningqr} alt="personscanning" className="person" />
          <div className="featureqrscanningContent">QR Scanning</div>
        </div>

        <div className="sliderimagesyoung">
          <img src={youngmale} alt="personscanning" className="young" />
          <div className="featureyoungmaleContent">Video Calling</div>

        </div>

        <div className="sliderimagesside">
          <img src={sideviewwoman} alt="personscanning" className="side" />
          <div className="featuresideviewwomanContent">Add Multiple Addresses</div>
        </div>

        <div className="sliderimagesbusiness">
          <img src={businesspeople} alt="personscanning" className="business" />
          <div className="featurebusinesspeopleContent">Add Members</div>
        </div>

        <div className="sliderimagesmedium">
          <img src={mediumshotwoman} alt="personscanning" className="medium" />
          <div className="featuremediumshotwomanContent">Chat when on Call</div>
        </div>

        <div className="sliderimagesapplication">
          <img
            src={bussinessapplication}
            alt="personscanning"
            className="application"
          />
             <div className="featurebusinessapplicationContent">Ring on Multiple Devices</div>
        </div>

        <div className="sliderimagesworking">
          <img src={peopleworking} alt="personscanning" className="working" />
          <div className="featurepeopleworkingContent">Conference Video Call</div>
        </div>

     
      </Slider>
    </div>
    </div>

    <div className="unlockpremium"><button className="unlockpremiumbutton" onClick={handleClick}>UNLOCK MORE WITH PREMIUM</button></div>
    </div>
  );
};

export default MultiItemCarousel;