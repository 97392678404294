import React from "react";
import { useEffect } from "react";

const Termspolicy = () => {
  useEffect(() => {
    //for rendering at the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="termspage" style={{ padding: "10px", marginLeft: "20px" }}>
      <div
        style={{
          fontSize: "28px",
          fontWeight: "600",
          fontFamily: "sans-serif",
          textAlign:"center"
        }}
      >
       Terms & Conditions 
      </div>
      <br />
      <div
        style={{
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        End User License Agreement
      </div>
      <br />
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        Last Updated On: March 19, 2024 
      </div>

      <p>
      WhoosiT is licensed to You (End-User) by Sysquare Technologies Pvt. Ltd., for use only under the terms of this License Agreement. 
      </p>

      
      <br />
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        1.Acceptance of Terms: 
      </div>
      <p>
      By downloading the WhoosiT app from Google Play Store or Apple App Store, you indicate your agreement to be bound by all the terms and conditions of this License Agreement. You accept and acknowledge this License Agreement. Please note that neither Apple nor Google is a party to this License Agreement, and they are not bound by any provisions or obligations related to the Application. 
      </p>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        2. Application Description: 
      </div>
     <p>WhoosiT is a software designed to facilitate interactions between visitors and residents or vehicle owners. It generates QR codes for Home, Vehicle, Warehouse, Hotels, Office, Manufacturing Units and society. The app is available on both Google Play Store and Apple App Store, and it is customized for mobile devices to allow visitors to connect with owners or residents remotely. </p>
        
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        3. Scope of License 
      </div>
      <ol type="a">
        <li>
        You are granted a non-transferable, non-exclusive, non-sub licensable license to install and use the WhoosiT app on smart mobile devices that you own or control, subject to the Usage Rules outlined in this section and the App Store and Play Store Terms of Service. 
        </li>
        <li>
         
          The license also covers updates provided by the Licensor unless a separate license is provided for such updates. 
        </li>
        <li>
        You may not share, sell, rent, lend, lease, or redistribute the Application. Reverse engineering, translation, disassembly, modification, or creation of derivative works without prior written consent from WhoosiT is strictly prohibited. 
        </li>
        <li>
        You may not reverse engineer, translate, disassemble, integrate, decompile, integrate, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Application, or any part thereof (except with WhoosiT’s prior written consent). 
        </li>
        <li>
        Licensor reserves the right to modify the terms and conditions of licensing
        </li>
      </ol>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        4. Technical Requirements: 
      </div>

      <p>
      The Licensor aims to keep the Application updated to comply with new firmware versions and hardware. Users are responsible for ensuring their end-user devices meet the technical specifications mentioned. The Licensor reserves the right to modify technical specifications as deemed appropriate. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        5. Maintenance and Support: 
      </div>

      <p>
      The Licensor is solely responsible for providing maintenance and support services for the licensed Application. For assistance, users can contact the Licensor via the email address listed in the App Store and Play Store Overview. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        6. Use of Data: 
      </div>
      <p>
      By using the WhoosiT app, you acknowledge that the Licensor may access and adjust your downloaded content and personal information. The use of such data is subject to the Licensor's legal agreements and privacy policy. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        7.User-Generated Contributions: 
      </div>
      <p>
      The Application may allow users to contribute content, subject to certain conditions. Users must ensure their Contributions comply with applicable laws and these Terms & Conditions. Contributions may be viewable by other users, and users grant WhoosiT an unrestricted license to use and distribute such Contributions. 
      </p>
     

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        8.Liability: 
      </div>

      <p>
      Licensor is not accountable for damages caused by a breach of duties. Users are encouraged to use backup functions to prevent data loss. In case of alterations to the Application, access may be restricted. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        9.Warranty: 
      </div>

      <p>
      WhoosiT warrants that the Application is free of malware at the time of download and functions as described. No warranty is provided for unauthorized modifications or use with inappropriate hardware or software. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        10. Product Claims: 
      </div>
      <p>
      WhoosiT, neither Apple nor Google, is responsible for addressing claims related to the Application, including product liability claims and compliance with legal or regulatory requirements. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        11. Legal Compliance: 
      </div>
      <p>
      Users must confirm they are not located in a country subject to an India Government embargo or listed as a "terrorist supporting" country. 
      </p>


      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        12. Contact Information:  
      </div>
      <p>For inquiries or claims, contact WhoosiT support at the provided address and email. </p>
      <p style={{margin:0, padding:0}}>WhoosiT support </p>
      <b style={{margin:0, padding:0}}>Sysquare Technologies Pvt. Ltd.</b>
      <p style={{margin:0, padding:0}}>H18-21 Blessings Plaza, Subhash Naga</p>
      <p style={{margin:0, padding:0}}>Jaipur, Rajasthan-302016  </p>
      <p style={{marginTop:0, padding:0}}>contact@whoosit.in </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        13. Termination: 
      </div>
      <p>The license is valid until terminated. Failure to comply with terms will result in automatic termination. Upon termination, users must cease all use and destroy all copies of the Application. </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        14. Third-Party Terms and Beneficiary: 
      </div>
      <p>Apple's and Google’s subsidiaries are third-party beneficiaries of this License Agreement. Apple and Google has the right to enforce this agreement against users. </p>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        15. Intellectual Property Rights:  
      </div>
      <p>WhoosiT is responsible for addressing any third-party claims of intellectual property infringement related to the Application. </p>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        16. Applicable Law:  
      </div>
      <p>This license agreement is governed by the laws of India, excluding conflicts of law rules. </p>
     

    </div>
  );
};

export default Termspolicy;
