import React from "react";
import "../videoCalling/works.css";
import ReactPlayer from "react-player";

const VideoCall = () => {
 

  return (
    <div className="howitworks" id="HowItWorks" >
      <p className="howitworksHeadingA">How it works</p>
      <div className="worksblueline">
        <div className="howitworksblueunderline"></div>
      </div>
      <p className="howitworksHeadingB">
      QR based video calling and door unlocking
      </p>

      <div className="howitworksvideoContainer">
        <div className="howitworksvideo">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=64u7fHsjiqI&ab_channel=SysquareExecutive"
            width={"100%"}
            controls
            className="reactplayervideo"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoCall;
