import React from "react";
import "../cards/cards.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const qr = process.env.PUBLIC_URL + "/images/Website Images/Scan Tile.png";
const youngman =
  process.env.PUBLIC_URL + "/images/Website Images/Converse TIle Video call.png";
const videocall =
  process.env.PUBLIC_URL + "/images/Website Images/Log Tile.png";

const MultiActionAreaCard = () => {
  const responsive = {
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="cardsSection">
        {/* 1st slide */}
        <div className="scanCard">
          <img src={qr} alt="qr" className="scanCardImg" />

          <div className="scanCardContent">
            <p className="scanCardContentA">SCAN</p>
            <p className="scanCardContentB">
            Connect owner, by scanning the QR code
            </p>
            <a href="#HowItWorks" className="scanCardButton">
              Learn More
            </a>
          </div>
        </div>

        {/* 2nd slide */}
        <div className="callCard">
          <img src={youngman} alt="qr" className="scanCardImg" />
          <div className="callCardContent">
            <p className="callCardContentA">CONVERSE</p>
            <p className="callCardContentB">
            Secured, hassle free communication
            </p>
            <a href="#HowItWorks" className="callCardButton">
              Learn More
            </a>
          </div>
        </div>

        {/* 3rd slide */}

        <div className="connectCard">
          <img src={videocall} alt="qr" className="scanCardImg" />
          <div className="connectCardContent">
            <p className="connectCardContentA">LOG</p>
            <p className="connectCardContentB">
            Keep every call log at your finger tips
            </p>
            <a href="#HowItWorks" className="connectCardButton">
              Learn More
            </a>
          </div>
        </div>
      </div>

      {/* for mobile make it carousel */}

      <div className="cardSectionMobile">
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // Server-side rendering support
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="scanCardMobile">
            <img src={qr} alt="qr" className="scanCardImgMobile" />

            <div className="scanCardContentMobile">
              <p className="scanCardContentAMobile">SCAN</p>
              <p className="scanCardContentBMobile">
              Connect owner, by scanning the QR code
              </p>
              <a href="#HowItWorks" className="scanCardButtonMobile">
                Learn More
              </a>
            </div>
          </div>

          {/* 2nd slide */}
          <div className="callCardMobile">
            <img src={youngman} alt="qr" className="scanCardImgMobile" />
            <div className="callCardContentMobile">
              <p className="callCardContentAMobile">CONVERSE</p>
              <p className="callCardContentBMobile">
              Secured, hassle free communication
              </p>
              <a href="#HowItWorks" className="callCardButtonMobile">
                Learn More
              </a>
            </div>
          </div>

          {/* 3rd slide */}

          <div className="connectCardMobile">
            <img src={videocall} alt="qr" className="scanCardImgMobile" />
            <div className="connectCardContentMobile">
              <p className="connectCardContentAMobile">LOG</p>
              <p className="connectCardContentBMobile">
              Keep every call log at your finger tips
              </p>
              <a href="#HowItWorks" className="connectCardButtonMobile">
                Learn More
              </a>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default MultiActionAreaCard;
