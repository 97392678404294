import React from "react";

import "../header/header.css";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const whoosit = process.env.PUBLIC_URL + "images/Blue tag line logo.png";
const telephone = process.env.PUBLIC_URL + "/images/Group 940@2x (1).png";

const Header = ({isUnlockButton}) => {
  const navigate = useNavigate();
  const website = () => {
    navigate("/");
  };
  return (
    <div className="heading">
      <div className="headinglogo">
        <img
          src={whoosit}
          alt="whoositlogo"
          className="headerwhoositlogo"
          onClick={website}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="headerSectionRight">
      <div className="headerRight">
   <span><a href="/plan" className="headerUnlockPremiumLink"><span className="headerUnlockPremium">Unlock Premium</span></a></span>
        <span className="headerFaq">
          <HashLink
            className="headeranchorfaq"
            smooth
            to="/#frequentlyaskedquestion"
          >
            FAQs
          </HashLink>
        </span>
       
        <span className="headerContact"> <img src={telephone} alt="telephone" className="headerphone" />&nbsp;&nbsp;<a href="tel:+91-8824693997" style={{color:"black", textDecoration:"none"}}>+91-8824693997</a></span>
      </div>
    </div>
    </div>
  );
};

export default Header;
