import React from "react";
import "../aboutus/aboutus.css";
const aboutuswhoosit = process.env.PUBLIC_URL + "/images/Website Images/About Us_new.png";
const AboutUsSection = () => {
  return (
    //main div
    <div className="aboutusHome">
      {/* content part division */}
      <div className="aboutusHomeContent">
        {/* content part all heading */}
        <div className="aboutusHomeContentHeading">
          <div className="aboutusHomeContentHeadingA">ABOUT US</div>
          <div className="aboutusHomeContentHeadingB">Welcome to WhoosiT</div>
          <div className="aboutusHomeContentline"></div>
          <div className="aboutusHomeContentHeadingC">
            Connecting on WhoosiT with QR
          </div>
        </div>
        {/* content data */}
        <div className="aboutusHomeContentData">
          <p className="aboutusHomeContentDataPara">
          WhoosiT revolutionizes entry management through QR codes and mobile devices, replacing doorbells and intercoms. Prioritizing security, it allows unlocking doors via smartphone after verifying visitors through video calls.
          </p>
        </div>
      </div>

      {/* image part division */}
      <div className="aboutusHomeImage">
        <div className="aboutuswhoositimage">
          <img
            src={aboutuswhoosit}
            alt="aboutus"
            className="whoositaboutimage"
          />
        </div>
       
      </div>
    </div>
  );
};

export default AboutUsSection;
