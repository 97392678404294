
const popupimage = process.env.PUBLIC_URL + "/images/Homeborder.png";
const vehicle=process.env.PUBLIC_URL + "/images/vehicleborder.png";
const hotels=process.env.PUBLIC_URL + "/images/hotelborder.png";
const work=process.env.PUBLIC_URL + "/images/Website Images/Work pop-up.png";
const lostandfound=process.env.PUBLIC_URL + "/images/Website Images/Lost &amp; Found Popup.png";
const society=process.env.PUBLIC_URL + "/images/societyborder.png";
export const posts = [
   {
      
    title: "HOME",
    image:popupimage,
    description:"WhoosiT serves as a comprehensive security gateway, leveraging QR code technology and video calling to provide residents with a secure and convenient means of managing visitor access to their homes.",
    subheadingA:"1.Visitor Identification and Enhanced Security: ",
    subheadingAdescription:"Easily identify visitors through QR codes scanned at the entry point, ensuring only authorized individuals gain access to the premises bolstering security measures.",
    subheadingB:"2.EPABX Systems: ",
    subheadingBdescription:"Traditional intercom systems will be replaced by QR-enabled video calling. Visitors or delivery persons can scan a QR code at the entrance to communicate with residents, providing enhanced security and convenience.",
    subheadingC:"3.Real-time Communication and Monitoring: ",
    subheadingCdescription:"Communicate instantly with visitors via video calls, enabling seamless interaction without the need for physical presence, enhancing convenience and safety.",
    subheadingD:"4.Remote Access Control: ",
    subheadingDdescription:"Grant or deny access remotely through the app, offering residents greater control and flexibility over who enters their premises."

},
{
    title:"VEHICLES",
    image:vehicle,
    description:"WhoosiT offers a robust solution for vehicle identification and communication, leveraging QR technology and video calling to streamline parking management and enhance security measures for vehicle owners and parking authorities alike.",
    subheadingA:"1.Vehicle Identification:",
    subheadingAdescription:"Utilize QR codes assigned to vehicles, facilitating quick and accurate identification, enabling seamless parking management.",
    subheadingB:"2.Real-time Communication: ",
    subheadingBdescription:"Enable direct communication between the app user and the vehicle owner through video calling, allowing for immediate resolution in case of parking violations or damage.",
    subheadingC:"3.Parking Enforcement:",
    subheadingCdescription:"Empower authorities or property owners to swiftly address parking violations by contacting the vehicle owner through the app, reducing congestion and ensuring compliance.",
    subheadingD:"4.Damage Reporting: ",
    subheadingDdescription:"Simplify the process of reporting damages to a parked vehicle by enabling instant communication between the concerned parties, expediting insurance claims or necessary repairs.",
    subheadingE:"5.Enhanced Security: ",
    subheadingEdescription:"Replace traditional methods of leaving notes or waiting for the vehicle owner's return by initiating secure video calls, ensuring better monitoring and resolution of issues."

},
{
title:"HOTELS",
image:hotels,
description:"WhoosiT serves as a bridge between guests and hotel services, leveraging QR technology and video calling to enhance communication, streamline services, and ensure a memorable and convenient stay experience for guests while optimizing operations for hotel staff.",
subheadingA:"1.Effortless Guest Identification: ",
subheadingAdescription:"Utilize QR codes for seamless guest identification, streamlining check-ins and ensuring a personalized experience from the moment guests arrive.",
subheadingB:"2.Instant Communication: ",
subheadingBdescription:"Enable direct communication between guests and hotel staff through video calling, allowing guests to request services, order food, or report any issues promptly.",
subheadingC:"3.Enhanced Room Service: ",
subheadingCdescription:"Simplify the process of ordering food, requesting amenities, or reporting maintenance issues by initiating secure video calls directly to the respective hotel departments.",
subheadingD:"4.Real-time Assistance: ",
subheadingDdescription:"Provide guests with immediate access to hotel staff for assistance, enhancing customer satisfaction and ensuring quick resolution of concerns or requests.",
subheadingE:"5.Streamlined Reception Services: ",
subheadingEdescription:"Enable guests to contact the reception desk instantly through video calling, reducing wait times and enhancing overall guest experience.",
subheadingF:"6.Efficient Problem Resolution: ",
subheadingFdescription:"Facilitate quick resolution of issues by enabling guests to visually communicate any damages or problems in their rooms, ensuring timely maintenance or repairs."
    }
,
{
    
        title:" Work",
        image:work,
        description:"WhoosiT is a tool for visitor management for offices, workplaces, warehouses, and manufacturing units, harnessing QR technology and video calls to ensure controlled access, efficient operations, and a secure and efficient environment for staff, management, and visitors. ",
        subheadingA:"1.Visitor Identification: ",
        subheadingAdescription:" Implement QR codes for focused and secure visitor identification, ensuring a streamlined check-in process and controlled access to the office & manufacturing premises. ",
        subheadingB:"2.Accentuated Security Measures:",
        subheadingBdescription:"Supersede traditional methods by employing video calling to visually authenticate visitors, and permit staff and management with an additional layer of security before allowing access to restricted areas.",
        subheadingC:"3.Efficient Delivery Management: ",
        subheadingCdescription:"Enable delivery personnel to communicate directly with employees and the designated warehouse staff through video calls, guaranteeing smooth and secure delivery of packages, goods, and machinery.",
        subheadingD:"4.Visitor Log & Tracking: ",
        subheadingDdescription:" Cataloging records of visitor activity, aiding in tracking and ensuring a record of who enters the office & manufacturing premises and for what purpose.",
       
    
},
{
        title:"SOCIETY",
        image:society,
        description:"WhoosiT acts as a comprehensive tool for visitor management, communication, and community engagement within residential societies, leveraging QR technology and video calling to enhance security, convenience, and connectedness among residents and management.",
        subheadingA:"1.Visitor Identification: ",
        subheadingAdescription:"Implement QR codes for visitors, enabling a streamlined check-in process and controlled access to the residential premises or community facilities.",
        subheadingB:"2.Efficient Delivery Management: ",
        subheadingBdescription:"Enable delivery personnel to communicate directly through video calls, ensuring secure and convenient delivery of packages or services to residents' doorsteps.",
        subheadingC:"3.Emergency Reporting and Response: ",
        subheadingCdescription:"Residents can report damages, emergencies, or safety concerns through the app, initiating quick communication with management or maintenance teams for prompt resolution.",
        subheadingD:"4.Visitor Log and Tracking: ",
        subheadingDdescription:"Maintain a log of visitor activities within the society, aiding in monitoring and ensuring a record of who enters the premises and for what purpose.",
      
    
},

{
        title:"Lost & Found",
        image:lostandfound,
        description:"WhoosiT is a comprehensive tool designed to streamline the management of lost and found items. It utilizes video calling and QR scanning technology to swiftly and accurately reunite lost items with their rightful owners. This ensures a smooth and efficient process while safeguarding personal belongings. ",
        subheadingA:"1.Item Identification: ",
        subheadingAdescription:"Employing QR codes on your personal belongings for a systematic and secure identification of the lost items, ensuring a swift and steady process of obtaining the lost items.",
        subheadingB:"2.Efficient Communication and Coordination: ",
        subheadingBdescription:"Video calling allows for real-time communication between the finder and the owner of the lost item. This enables coordination between the two parties for a reliable return process of the lost items to its owner. This reduces wait times and enhances communication efficiency.",
        subheadingC:"3.Accentuated Security Measures: ",
        subheadingCdescription:"Easily identify the rightful owner of the lost item and initiate the return process as soon as possible by safeguarding the valuable personal belongings of the users.",
        subheadingD:"4.Enhanced User Experience:  ",
        subheadingDdescription:"Streamline the process of reporting and reclaiming lost items. With the help of a QR scanner and video calling features, users can rapidly and easily register lost items and facilitate their return.",
      
    
},
// {
//         title:"SOCIETY",
//         image:society,
//         description:"WhoosiT acts as a comprehensive tool for visitor management, communication, and community engagement within residential societies, leveraging QR technology and video calling to enhance security, convenience, and connectedness among residents and management.",
//         subheadingA:"1.Visitor Identification: ",
//         subheadingAdescription:"Implement QR codes for visitors, enabling a streamlined check-in process and controlled access to the residential premises or community facilities.",
//         subheadingB:"2.Efficient Delivery Management: ",
//         subheadingBdescription:"Enable delivery personnel to communicate directly through video calls, ensuring secure and convenient delivery of packages or services to residents' doorsteps.",
//         subheadingC:"3.Emergency Reporting and Response: ",
//         subheadingCdescription:"Residents can report damages, emergencies, or safety concerns through the app, initiating quick communication with management or maintenance teams for prompt resolution.",
//         subheadingD:"4.Visitor Log and Tracking: ",
//         subheadingDdescription:"Maintain a log of visitor activities within the society, aiding in monitoring and ensuring a record of who enters the premises and for what purpose.",
      
    
// }
]