import React from "react";
// import {Box, Grid, Typography } from "@mui/material";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
const whoosit =
  process.env.PUBLIC_URL + "/images/White tag line logo.png";
const googleplay = process.env.PUBLIC_URL + "/images/googleplay.png";
const applestore = process.env.PUBLIC_URL + "/images/appstore.png";

const phone = process.env.PUBLIC_URL + "/images/ic_call_24px@2x.png";
const email = process.env.PUBLIC_URL + "images/ic_email_24px@2x.png";


const linkedin = process.env.PUBLIC_URL + "/images/linkedinbox.jpg";
const twitter = process.env.PUBLIC_URL + "/images/Group 76.jpg";
const instagram = process.env.PUBLIC_URL + "/images/instagram.jpg";

 
const Footer = () => {


 
  const navigate = useNavigate();

  const handleprivacylink = () => {
    navigate("/privacy");
  };
  const handleTermslink = () => {
    navigate("/terms");
  };

  const website = () => {
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
    }
  };
  return (
    <>
      <div className="footerSection">
        <Grid
          container
          spacing={2}
          style={{
            padding: '45px',
            backgroundColor: "#19191c",
            color: "white",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="footerwhoositlogoSection">
              <div className="footerwhoositlogo">
                <img
                  src={whoosit}
                  alt="whoosit"
                  className="footerwhoositlogoimg"
                  onClick={website}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                className="footerwhoosittext"
                style={{ width: "fit-content" }}
              >
                We are available on
              </div>
              <div className="footerdownloads">
                <div>
                  <img src={googleplay} alt="googleplay" />
                </div>
                <div>
                  <img src={applestore} alt="applestore" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="footerQuickContacts">
              <div className="footerQuickContact">Quick Contact</div>
              <div className="footerQuickContactquickcontact1">
                H 18-21,Blessing plaza, First Floor, Subhash Nagar shopping centre,
                Jaipur,Rajasthan 302016
              </div>
              <div className="footerQuickContactquickcontact2" style={{paddingBottom:"10px",paddingTop:"10px"}}>
                <img
                  src={phone}
                  style={{ width: "18px", height: "18px" }}
                  alt="phone"
                />
                &nbsp;&nbsp;&nbsp; <a href="tel:+91-8824693997" style={{color:"grey" ,textDecoration:"none"}}>+91-8824693997</a>
              </div>
              <div className="footerQuickContactquickcontact3" style={{paddingBottom:"10px"}}>
                <img
                  src={email}
                  style={{ width: "18px", height: "18px" }}
                  alt="email"
                />{" "}
                &nbsp;&nbsp; <a href={"https://mail.google.com/mail/?view=cm&fs=1&to=contact@whoosit.in"} className="mailsend" style={{color:"grey" , textDecoration:"none"}}>
                contact@whoosit.in
                </a>
              </div>
              <div className="footerlogos">
               
                <a href="https://www.linkedin.com/login">
                  <img
                    src={linkedin}
                    style={{ width: "32px", height: "31px" }}
                    alt="linkedin"
                  />
                </a>
                <a href="https://twitter.com/i/flow/login">
                  <img
                    src={twitter}
                    style={{ width: "32px", height: "31px" }}
                    alt="twitter"
                  />
                </a>
                <a href="https://www.instagram.com/accounts/login/?hl=en">
                  <img
                    src={instagram}
                    style={{ width: "32px", height: "31px" }}
                    alt="instagram"
                  />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="footervision">
              <div className="footerourvision">Our Vision</div>
              <div style={{ color: "grey" }} className="footerourvisionContent">
                Empowering effortless visitor recognition, secure access and
                instant alert with QR technology prioritising convenience and
                fortifying property security.
              </div>
            </div>
          </Grid>
        </Grid>
     
        <div className="footerSection2">
          <div className="TandC">
            <div className="termcondition">
              <button
                onClick={handleTermslink}
                style={{
                  textDecoration: "underline",
                  fontWeight: "600",
                  color: "black",
                  cursor: "pointer",
                  borderStyle: "none",
                  backgroundColor: "transparent",
                }}
                className="tandcText"
              >
                Terms & Conditions
              </button>
            </div>
          </div>

          <div className="privacy">
            <div className="privatepolicy">
              <button
                onClick={handleprivacylink}
                style={{
                  textDecoration: "underline",
                  fontWeight: "600",
                  color: "black",
                  cursor: "pointer",
                  borderStyle: "none",
                  backgroundColor: "transparent",
                }}
                className="privacyText"
              >
                Privacy Policy
              </button>
            </div>
          </div>
        </div>
        <div className="footerSection3">
          <div className="copyright">
           <div> &copy;2024 WhoosiT by &nbsp;
            <a className="sysquarelink" href="https://www.sysquare.com/">
              Sysquare Technologies Pvt. Ltd.
            </a></div>
            <div></div>&nbsp; All Rights Reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
