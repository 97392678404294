import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import Contact from '../contactus/contact'
import Cardplan from '../cardplan/cardplan';
import { useEffect } from 'react';

const Plan = () => {
  useEffect(() => {   //for rendering at the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="plans">
        <Header isUnlockButton={false}/>
        <Cardplan/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Plan