import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../banner/banner.css";
import { useEffect } from "react";

const image1 = process.env.PUBLIC_URL + "/images/Website Images/First_Banner.png";
const image2 = process.env.PUBLIC_URL + "/images/Website Images/Second_Banner.png";
const image3 = process.env.PUBLIC_URL + "/images/Website Images/Third_Banner.png";
const arrowleft = process.env.PUBLIC_URL + "/images/Group 64.png";
const arrowright = process.env.PUBLIC_URL + "/images/Group 63.png";

const Banner = () => {


  const CustomPrevArrow = (props) => (
    <button {...props} className="custom-prev-arrow">
      <img src={arrowleft} className="arrowleft" />
    </button>
  );

  const CustomNextArrow = (props) => (
    <button {...props} className="custom-next-arrow">
      <img src={arrowright} className="arrowright" />
    </button>
  );
  const settings = {
    // dots: true,
     infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="slider-container">
      <Slider
        {...settings}
        className="slider"
        style={{ overflow: "hidden", padding: "0px" }}
      >
        <div className="slide1">
          <img className="slideimg1" src={image1} />

          <div className="headerContentSlide1">
       

          <p className="content1slide1">SCAN. CONVERSE. LOG</p>
          <p className="content2slide1">
            One click away from virtual
            <br /> interaction
          </p>
          </div>
        </div>

        <div className="slide2">
          <img className="slideimg2" src={image2} />

          <div className="headerContentSlide2">
          <p className="content1slide2">
            Parking Relief
            <br /> QR For Vehicle
          </p>
          <p className="content2slide2">
            Scan to connect , end worries of wrong parking
            <br /> inconveniences for all
          </p>
          </div>
        </div>

        <div className="slide3">
          <img className="slideimg3" src={image3} />

          <div className="headerContentSlide3">

          <p className="content1slide3">Secure Homes</p>
          <p className="content2slide3" >
            QR-Based App For Visitor Identification
          </p>
          <p className="content3slide3" >
            Ensuring resident safety with hassle free verification and
            communication
          </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
