import React from "react";
import { useEffect } from "react";

const Privacypolicy = () => {
  useEffect(() => {
    //for rendering at the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacypage" style={{ padding: "10px", margin: "20px" }}>
      <div
        style={{
          fontSize: "28px",
          fontWeight: "600",
          fontFamily: "sans-serif",
          textAlign:"center"
        }}
      >
       Privacy Policy 
      </div>
      <div
        style={{
          fontSize: "28px",
          fontWeight: "600",
          fontFamily: "sans-serif",
          
        }}
      >
      Last Updated on: March 18, 2024 
      </div>
      <p>
      Thank you for choosing WhoosiT! We are committed to protecting your privacy and ensuring that your personal information is handled responsibly. This Privacy Policy outlines how we collect, use, share, and protect your information when you use our app, WhoosiT. Please take the time to read this policy carefully, as it is important. If you do not agree with any terms in this privacy notice, we kindly ask you to discontinue the use of our services immediately. 
      </p>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        1.Contact Information: 
      </div>
      <p>For any queries or concerns regarding your privacy or this Privacy Policy, you can reach out to us at <a href="mailto:contact@whoosit.in">contact@whoosit.in</a> Additionally, you can visit our website at <a href="https://whoosit.in/">https://whoosit.in/</a> for more information. </p>
        
      

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
       2. Information We Collect: 
      </div>
      <ol type="a" >
      <li>Personal Information: We collect personal information that you provide to us, such as your phone number, name, email address, and other relevant details required for the use of our services. </li>
      <li>Automatically Collected Information: Some information, including your Internet Protocol (IP) address, browser characteristics, and device information, is collected automatically when you visit our services. </li>
      <li>Geolocation and Mobile Device Information: We collect information regarding your geolocation, mobile device details, and may send push notifications when you use our app.</li>
     
       </ol>
 
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        3.How We Use Your Information: 
      </div>
      <ol type="a">
        <li>
        We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with legal obligations, and/or your consent. 
        </li>
       <li>
       We use the information collected: 
       <ol type="i">
        <li>To facilitate account creation and the sign-in and sign-up process. </li>
       </ol>
       </li>
     
      </ol>
      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        4.Sharing Information:  
      </div>
      <ol type="a">
        <li>We only share information under the following circumstances: 
        <ol type="i">
          <li>With your consent. </li>
          <li>To comply with laws. </li>
          <li>To provide services to you. </li>
          <li>To protect your rights. </li>
          <li>To fulfill business obligations</li>
        </ol>
        </li>
      </ol>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        5.To fulfill business obligations. 
      </div>
      <ol type="a">
        <li>We may share information with the following third parties:
          <ol type="i">
            <li>ConnectyCube </li>
            <li>Firebase </li>
            <li>Google Account and Apple Account </li>
          </ol>
        </li>
      </ol>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        6.Cookies and Tracking Technologies: 
      </div>
      <p>
      We use cookies and other tracking technologies to enhance your experience on WhoosiT. You can manage your preferences regarding these technologies. 
      </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        7.Information Transfer: 
      </div>
      <p>
      Your information may be transferred internationally. By using WhoosiT, you consent to the transfer of your information. 
      </p>


      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        8.Data Retention: 
      </div>
      <p>We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required by law. </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        9.Security Measures: 
      </div>
   <p>We employ security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        10.Minors:  
      </div>
    <p>WhoosiT is not intended for use by minors, and we do not knowingly collect information from individuals under the age of 18. </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        11.Privacy Rights:  
      </div>
   <p>You have the right to access, correct, or delete your personal information. Contact us if you wish to exercise these rights. </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
        12.Updates to this Notice: 
      </div>
    <p>We may update this Privacy Policy periodically. Any changes will be posted on our website, and you are encouraged to review the policy regularly. </p>

      <div
        style={{
          fontSize: "22px",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
      >
       13.Contact Us: 
      </div>
      <p>
      If you have any questions or concerns about this Privacy Policy, please contact us at WhoosiT support: 
      </p>
      <div style={{ marginLeft: "10px" }}>
        <a href="mailto:conatct@whoosit.in">conatct@whoosit.in</a>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <b style={{ fontFamily: "sans-serif" }}>
          Sysqaure Technologies Pvt. Ltd.
        </b>
      </div>
      <div style={{ marginLeft: "10px" }}>
        H18-21, Blessings Plaza, Subhash Nagar,
      </div>
    
      <div style={{ marginLeft: "10px" }}>Jaipur, Rajasthan 302016</div>
   
    </div>
  );
};

export default Privacypolicy;
