import React, { useState } from "react";
import "../useCases/useCases.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { posts } from "../useCases/useCasesPopup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const arrowleftslider = process.env.PUBLIC_URL + "/images/Group 63@3x.png";
const arrowrightslider = process.env.PUBLIC_URL + "/images/Group 63@3x1.png";
const CustomPrevArrowSlider = ({ onClick }) => (
  <button
    className="custom-arrow-featureSlider previousfeatureSlider"
    onClick={onClick}
  >
    <img src={arrowrightslider} alt="" className="arrowleftfeatureSlider" />
  </button>
);

const CustomNextArrowSlider = ({ onClick }) => (
  <button
    className="custom-arrow-featureSlider nextfeatureSlider"
    onClick={onClick}
  >
    <img src={arrowleftslider} className="arrowrightfeatureSlider" alt="" />
  </button>
);
const settings = {
  // dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <CustomPrevArrowSlider />,
  nextArrow: <CustomNextArrowSlider />,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));



const carpark = process.env.PUBLIC_URL + "/images/car-car-park@2x.png";
const modernhouse =
  process.env.PUBLIC_URL +
  "/images/modern-house-entrance-japanese-building@2x.png";
const shotman =
  process.env.PUBLIC_URL + "/images/full-shot-man-delivering-box@2x.png";
const girlwithsunglasses =
  process.env.PUBLIC_URL +
  "/images/Website Images/Work Use case Option 4.png";
const industrialpark =
  process.env.PUBLIC_URL +
  "/images/building@2x.png";
const building = process.env.PUBLIC_URL + "/images/Website Images/Lost &amp; Found (646X499).png";


// carousel setting

const responsive = {
  tablet: {
    breakpoint: { max: 1024, min: 540 },
    items: 2,
    slidesToSlide: 2, 
  },
  mobile: {
    breakpoint: { max: 539, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};
const UseCases = () => {
  const [desktopVehicle, setdesktopVehicle] = useState(false);
  const [desktopHome, setdesktopHome] = useState(false);
  const [desktopHotels, setdesktopHotels] = useState(false);
  const [desktopOffice, setdesktopOffice] = useState(false);
  const [desktopSociety, setdesktopSociety] = useState(false);
  const [desktopManufacturingUnits, setdesktopManufacturingUnits] =
    React.useState(false);

  // desktop vehicle
  const handledesktopVehicleClick = () => {
    setdesktopVehicle(true);
  };
  const handledesktopVehicleClose = () => {
    setdesktopVehicle(false);
  };

  // desktop Home
  const handledesktopHomeClick = () => {
    setdesktopHome(true);
  };
  const handledesktopHomeClose = () => {
    setdesktopHome(false);
  };
  //desktop office
  const handledesktopOfficeClick = () => {
    setdesktopOffice(true);
  };
  const handledesktopOfficeClose = () => {
    setdesktopOffice(false);
  };

  //desktop society
  const handledesktopSocietyClick = () => {
    setdesktopSociety(true);
  };
  const handledesktopSocietyClose = () => {
    setdesktopSociety(false);
  };
  //desktop Hotels

  const handledesktopHotelsClick = () => {
    setdesktopHotels(true);
  };
  const handledesktopHotelsClose = () => {
    setdesktopHotels(false);
  };
  //desktop manufacturing units
  const handledesktopManufacturingUnitsClick = () => {
    setdesktopManufacturingUnits(true);
  };
  const handledesktopManufacturingUnitsClose = () => {
    setdesktopManufacturingUnits(false);
  };

  return (
    <>
      {/* for desktop */}
      <div className="useCases">
        <div className="useCaseHeader">
          <div className="useCaseHeading">Our Use Cases</div>
          <div className="useCaseWrap">
            <div className="useCaseLine"></div>
          </div>
          <div className="useCaseHeadingA">
            Home, Work, Hotels, Vehicles, Society, Lost & Found —  No matter wherever you want, we've got the perfect platform for you.
          </div>
          
          <div className="useCaseHeadingC"> NOW EVERY DOOR IS A SMART DOOR</div>
        </div>

        <div className="useCaseContent">
          <div className="useCaseContentItem1">
            <img
              className="useCaseContentItem1img"
              src={carpark}
              alt="carpark"
            />
            <div className="useCaseContentItem1Content">Vehicles</div>
            <div className="hoverclass1">
              <div className="useCasehoverclass1">
                <div className="hoverclass1head">Vehicle </div>

                <div className="useCasehoverlinemark">
                  <div className="useCasehoverline"></div>
                </div>

                <div className="hoverclass1content">
                  <p>
                    WhoosiT offers a robust solution for vehicle identification
                    and communication, leveraging QR technology and video
                    calling to streamline parking management and enhance
                    security measures for vehicle owners and parking authorities
                    alike.
                  </p>
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopVehicleClick}>
                      view More <ArrowRightAltIcon />
                    </Button>

                    <BootstrapDialog
                      onClose={handledesktopVehicleClose}
                      aria-labelledby="customized-dialog-title"
                      open={desktopVehicle}
                    >
                      <DialogContent
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #212E61",
                          margin: 0,
                          padding:"25px",
                        
                        }}
                      >
                        <Slider initialSlide={1} {...settings}>
                          {posts.map((post, index) => (
                            <div key={index} className="popupContentContainer">
                              <DialogTitle
                                sx={{
                                  m: 0,
                                  p: 2,
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "24px",
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                }}
                                style={{ backgroundColor: "white" }}
                                id="customized-dialog-title"
                              >
                                {post.title}
                              </DialogTitle>

                              <div className="popuplinemark">
                                <div
                                  className="popupline"
                                  style={{ backgroundColor: "black" }}
                                ></div>
                              </div>
                              <img
                                src={post.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              />

                              <Typography
                                gutterBottom
                                sx={{
                                  color: "black",
                                  fontSize: "18px",
                                  padding: "10px",
                                  fontFamily:"nunito"
                                }}
                              >
                                {post.description}
                              </Typography>

                              <Typography
                                gutterBottom
                                sx={{ color: "black", fontSize: "18px" , fontFamily:"nunito" }}
                              >
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "16px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingA}
                                </span>
                                {post.subheadingAdescription}
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ color: "black", fontSize: "18px" , fontFamily:"nunito" }}
                              >
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingB}
                                </span>
                                {post.subheadingBdescription}
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ color: "black", fontSize: "18px" , fontFamily:"nunito" }}
                              >
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingC}
                                </span>
                                {post.subheadingCdescription}
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ color: "black", fontSize: "18px" ,  fontFamily:"nunito"}}
                              >
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingD}
                                </span>
                                {post.subheadingDdescription}
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ color: "black", fontSize: "18px" , fontFamily:"nunito" }}
                              >
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingE}
                                </span>
                                {post.subheadingEdescription}
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{ color: "black", fontSize: "18px" , fontFamily:"nunito" }}
                              >
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingF}
                                </span>
                                {post.subheadingFdescription}
                              </Typography>
                            </div>
                          ))}
                        </Slider>
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="useCaseContentItem2">
            <img
              className="useCaseContentItem2img"
              src={shotman}
              alt="shotman"
            />
            <div className="useCaseContentItem2Content">Home</div>

            <div className="hoverclass2">
              <div className="useCasehoverclass2">
                <div className="hoverclass2head">Home</div>
                <div className="useCasehoverlinemark">
                  <div className="useCasehoverline"></div>
                </div>
                <div className="hoverclass2content">
                  <p>
                    WhoosiT serves as a comprehensive security gateway,
                    leveraging QR code technology and video calling to provide
                    residents with a secure and convenient means of managing
                    visitor access to their homes.
                  </p>

                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopHomeClick}>
                      view More <ArrowRightAltIcon />
                    </Button>

                    <BootstrapDialog
                      onClose={handledesktopHomeClose}
                      aria-labelledby="customized-dialog-title"
                      open={desktopHome}
                    >
                      <DialogContent
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #212E61",
                          margin: 0,
                          padding: "40px",
                        }}
                      >
                        <Slider initialSlide={0} {...settings}>
                          {posts.map((post, index) => (
                            <div key={index} className="popupContentContainer">
                              <DialogTitle
                                sx={{
                                  m: 0,
                                  p: 2,
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "24px",
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                  fontFamily:"nunito"
                                }}
                                style={{ backgroundColor: "white" }}
                                id="customized-dialog-title"
                              >
                                {post.title}
                              </DialogTitle>

                              <div className="popuplinemark">
                                <div
                                  className="popupline"
                                  style={{ backgroundColor: "black" }}
                                ></div>
                              </div>
                              <img
                                src={post.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              />

                              <Typography
                                gutterBottom
                                sx={{
                                  color: "black",
                                  fontSize: "18px",
                                  padding: "10px",
                                  fontFamily:"nunito"
                                }}
                              >
                                {post.description}
                              </Typography>

                              <Typography gutterBottom sx={{ color: "black" , fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingA}
                                </span>
                                {post.subheadingAdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" , fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingB}
                                </span>
                                {post.subheadingBdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingC}
                                </span>
                                {post.subheadingCdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingD}
                                </span>
                                {post.subheadingDdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingE}
                                </span>
                                {post.subheadingEdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingF}
                                </span>
                                {post.subheadingFdescription}
                              </Typography>
                            </div>
                          ))}
                        </Slider>
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="useCaseContentItem3">
            <img
              className="useCaseContentItem3img"
              src={modernhouse}
              alt="modernhouse"
            />
            <div className="useCaseContentItem3Content">Hotels</div>
            <div className="hoverclass3">
              <div className="useCasehoverclass3">
                <div className="hoverclass3head">Hotels </div>
                <div className="useCasehoverlinemark">
                  <div className="useCasehoverline"></div>
                </div>
                <div className="hoverclass3content">
                  <p>
                    WhoosiT serves as a bridge between guests and hotel
                    services, leveraging QR technology and video calling to
                    enhance communication, streamline services, and ensure a
                    memorable and convenient stay experience for guests while
                    optimizing operations for hotel staff.
                  </p>
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopHotelsClick}>
                      view more <ArrowRightAltIcon />
                    </Button>

                    <BootstrapDialog
                      onClose={handledesktopHotelsClose}
                      aria-labelledby="customized-dialog-title"
                      open={desktopHotels}
                    >
                      <DialogContent
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #212E61",
                          margin: 0,
                          padding: "40px",
                        }}
                      >
                        <Slider initialSlide={2} {...settings}>
                          {posts.map((post, index) => (
                            <div key={index} className="popupContentContainer">
                              <DialogTitle
                                sx={{
                                  m: 0,
                                  p: 2,
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "24px",
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                 fontFamily:"nunito"
                                }}
                                style={{ backgroundColor: "white" }}
                                id="customized-dialog-title"
                              >
                                {post.title}
                              </DialogTitle>

                              <div className="popuplinemark">
                                <div
                                  className="popupline"
                                  style={{ backgroundColor: "black" }}
                                ></div>
                              </div>
                              <img
                                src={post.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              />

                              <Typography
                                gutterBottom
                                sx={{
                                  color: "black",
                                  fontSize: "18px",
                                  padding: "10px",
                                }}
                              >
                                {post.description}
                              </Typography>

                              <Typography gutterBottom sx={{ color: "black" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingA}
                                </span>
                                {post.subheadingAdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingB}
                                </span>
                                {post.subheadingBdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingC}
                                </span>
                                {post.subheadingCdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingD}
                                </span>
                                {post.subheadingDdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingE}
                                </span>
                                {post.subheadingEdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingF}
                                </span>
                                {post.subheadingFdescription}
                              </Typography>
                            </div>
                          ))}
                        </Slider>
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="useCaseContentItem4">
            <img
              className="useCaseContentItem4img"
              src={girlwithsunglasses}
              alt="girlwithsunglasses"
            />
            <div className="useCaseContentItem4Content">Work</div>
            <div className="hoverclass4">
              <div className="useCasehoverclass4">
                <div className="hoverclass4head">Work </div>
                <div className="useCasehoverlinemark">
                  <div className="useCasehoverline"></div>
                </div>
                <div className="hoverclass4content">
                  <p>
                  WhoosiT is a tool for visitor management for offices, workplaces, and manufacturing units, harnessing QR technology and video calls to ensure controlled access, efficient operations, and a secure and efficient environment for staff, management, and visitors.
                  </p>
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopOfficeClick}>
                      View More <ArrowRightAltIcon />
                    </Button>

                    <BootstrapDialog
                      onClose={handledesktopOfficeClose}
                      aria-labelledby="customized-dialog-title"
                      open={desktopOffice}
                    >
                      <DialogContent
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #212E61",
                          margin: 0,
                          padding: "40px",
                        }}
                      >
                        <Slider initialSlide={3} {...settings}>
                          {posts.map((post, index) => (
                            <div key={index} className="popupContentContainer">
                              <DialogTitle
                                sx={{
                                  m: 0,
                                  p: 2,
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "24px",
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                }}
                                style={{ backgroundColor: "white" }}
                                id="customized-dialog-title"
                              >
                                {post.title}
                              </DialogTitle>

                              <div className="popuplinemark">
                                <div
                                  className="popupline"
                                  style={{ backgroundColor: "black" }}
                                ></div>
                              </div>
                              <img
                                src={post.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              />

                              <Typography
                                gutterBottom
                                sx={{
                                  color: "black",
                                  fontSize: "16px",
                                  padding: "10px",
                                }}
                              >
                                {post.description}
                              </Typography>

                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingA}
                                </span>
                                {post.subheadingAdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingB}
                                </span>
                                {post.subheadingBdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingC}
                                </span>
                                {post.subheadingCdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingD}
                                </span>
                                {post.subheadingDdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingE}
                                </span>
                                {post.subheadingEdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingF}
                                </span>
                                {post.subheadingFdescription}
                              </Typography>
                            </div>
                          ))}
                        </Slider>
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="useCaseContentItem5">
            <img
              className="useCaseContentItem5img"
              src={industrialpark}
              alt="industrialpark"
            />
            <div className="useCaseContentItem5Content">
             Society
            </div>
            <div className="hoverclass5">
              <div className="useCasehoverclass5">
                <div className="hoverclass5head">Society </div>
                <div className="useCasehoverlinemark">
                  <div className="useCasehoverline"></div>
                </div>
                <div className="hoverclass5content">
                  <p>
                  WhoosiT acts as a comprehensive tool for visitor management,
                    communication, and community engagement within residential
                    societies, leveraging QR technology and video calling to
                    enhance security, convenience, and connectedness among
                    residents and management.
                  </p>
                  <React.Fragment>
                    <Button
                      variant="none"
                      onClick={handledesktopManufacturingUnitsClick}
                    >
                      View More <ArrowRightAltIcon />
                    </Button>

                    <BootstrapDialog
                      onClose={handledesktopManufacturingUnitsClose}
                      aria-labelledby="customized-dialog-title"
                      open={desktopManufacturingUnits}
                    >
                      <DialogContent
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #212E61",
                          margin: 0,
                          padding: "40px",
                        }}
                      >
                        <Slider initialSlide={4} {...settings}>
                          {posts.map((post, index) => (
                            <div key={index} className="popupContentContainer">
                              <DialogTitle
                                sx={{
                                  m: 0,
                                  p: 2,
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "18px",
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                  
                                }}
                                style={{ backgroundColor: "white", fontSize: "18px", fontFamily:"nunito" }}
                                id="customized-dialog-title"
                              >
                                {post.title}
                              </DialogTitle>

                              <div className="popuplinemark">
                                <div
                                  className="popupline"
                                  style={{ backgroundColor: "black" }}
                                ></div>
                              </div>
                              <img
                                src={post.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              />

                              <Typography
                                gutterBottom
                                sx={{
                                  color: "black",
                                  fontSize: "18px",
                                  padding: "10px",
                                 fontFamily:"nunito"
                                }}
                              >
                                {post.description}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingA}
                                </span>
                                {post.subheadingAdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingB}
                                </span>
                                {post.subheadingBdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "5px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingC}
                                </span>
                                {post.subheadingCdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px",fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingD}
                                </span>
                                {post.subheadingDdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingE}
                                </span>
                                {post.subheadingEdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito"}}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingF}
                                </span>
                                {post.subheadingFdescription}
                              </Typography>
                            </div>
                          ))}
                        </Slider>
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="useCaseContentItem6">
            <img
              className="useCaseContentItem6img"
              src={building}
              alt="building"
            />
            <div className="useCaseContentItem6Content">Lost & Found </div>
            <div className="hoverclass6">
              <div className="useCasehoverclass6">
                <div className="hoverclass6head">Lost & Found  </div>
                <div className="useCasehoverlinemark">
                  <div className="useCasehoverline"></div>
                </div>
                <div className="hoverclass6content">
                  <p>
                  WhoosiT is a comprehensive tool designed to streamline the management of lost and found items. It utilizes video calling and QR scanning technology to swiftly and accurately reunite lost items with their rightful owners. This ensures a smooth and efficient process while safeguarding personal belongings.
                  </p>
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopSocietyClick}>
                      View More <ArrowRightAltIcon />
                    </Button>

                    <BootstrapDialog
                      onClose={handledesktopSocietyClose}
                      aria-labelledby="customized-dialog-title"
                      open={desktopSociety}
                    >
                      <DialogContent
                        style={{
                          backgroundColor: "white",
                          border: "2px solid #212E61",
                          margin: 0,
                          padding: "40px",
                          paddingTop:"30px"
                        }}
                      >
                        <Slider initialSlide={5} {...settings}>
                          {posts.map((post, index) => (
                            <div key={index} className="popupContentContainer">
                              <DialogTitle
                                sx={{
                                  m: 0,
                                  p: 2,
                                  textAlign: "center",
                                  color: "black",
                                  fontSize: "24px",
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                 fontFamily:"nunito"
                                }}
                                style={{ backgroundColor: "white" }}
                                id="customized-dialog-title"
                              >
                                {post.title}
                              </DialogTitle>

                              <div className="popuplinemark">
                                <div
                                  className="popupline"
                                  style={{ backgroundColor: "black" }}
                                ></div>
                              </div>
                              <img
                                src={post.image}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "fill",
                                }}
                              />

                              <Typography
                                gutterBottom
                                sx={{
                                  color: "black",
                            
                                  padding: "10px",
                                  fontSize: "18px", fontFamily:"nunito"
                                }}
                              >
                                {post.description}
                              </Typography>

                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingA}
                                </span>
                                {post.subheadingAdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black" ,fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingB}
                                </span>
                                {post.subheadingBdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "5px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingC}
                                </span>
                                {post.subheadingCdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingD}
                                </span>
                                {post.subheadingDdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingE}
                                </span>
                                {post.subheadingEdescription}
                              </Typography>
                              <Typography gutterBottom sx={{ color: "black",fontSize: "18px", fontFamily:"nunito" }}>
                                <span
                                  style={{
                                    color: "#FF6C2C",
                                    fontSize: "18px",
                                    padding: "10px",
                                    fontFamily: "nunito",
                                  }}
                                >
                                  {post.subheadingF}
                                </span>
                                {post.subheadingFdescription}
                              </Typography>
                            </div>
                          ))}
                        </Slider>
                      </DialogContent>
                    </BootstrapDialog>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile and tablet */}

      <div className="ourusecaseCarousel">
        <h3 className="ourusecaseCarouselHeadingA">Our Use Cases</h3>

        {/* for blue line */}
        <div className="ourusecaseCarouselline">
          <div className="ourusecaseCarouselblueline"></div>
        </div>

        <p className="ourusecaseCarouselHeadingB">
          Home, Work, Hotels, Vehicles, Society, Lost & Found — No matter wherever you want, we've got the perfect platform for you.
        </p>
        <p className="ourusecaseCarouselHeadingC">
          NOW EVERY DOOR IS A SMART DOOR
        </p>

        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          // ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={false}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-0-px"
          className="ourusecaseslide"
        >
          <div className="carparkslide">
            <img src={carpark} alt="carpark" className="carparkslideimg" />
            <div className="carparkcontent">
              <p className="carparkcontentHead">Vehicles</p>
              <div className="ourusecasewhitelinemark">
                <div className="ourusecasewhiteline"></div>
              </div>
              <p
                className="carparkcontentpara"
                style={{
                  textAlign: "center",
                  margin: "0px 20px",
                  color: "white",
                }}
              >
                WhoosiT offers a robust solution for vehicle identification and
                communication, leveraging QR technology and video calling to
                streamline parking management and enhance security measures for
                vehicle owners and parking authorities alike.
              </p>
              <div className="Vehicleviewmorebutton">
                <div className="vehicleviewmore">
                  {" "}
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopVehicleClick}>
                      View More <ArrowRightAltIcon />
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>

          <div className="modernhouseslide">
            <img
              src={modernhouse}
              alt="carpark"
              className="modernhouseslideimg"
            />
            <div className="modernhousecontent">
              <p className="modernhouseHead">Hotels</p>
              <div className="ourusecasewhitelinemark">
                <div className="ourusecasewhiteline"></div>
              </div>
              <p
                className="modernhousecontentpara"
                style={{
                  textAlign: "center",
                  margin: "0px 20px",

                  color: "white",
                }}
              >
                WhoosiT offers a robust solution for vehicle identification and
                communication, leveraging QR technology and video calling to
                streamline parking management and enhance security measures for
                vehicle owners and parking authorities alike.
              </p>
              <div className="hotelsviewmorebutton">
                <div className="hotelsviewmore">
                  {" "}
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopHotelsClick}>
                      View More <ArrowRightAltIcon />
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>

          <div className="girlwithsunglassesslide">
            <img
              src={girlwithsunglasses}
              alt="carpark"
              className="girlwithsunglassesslideimg"
            />
            <div className="girlwithsunglassescontent">
              <p className="girlwithsunglassesHead">Work</p>
              <div className="ourusecasewhitelinemark">
                <div className="ourusecasewhiteline"></div>
              </div>
              <p
                className="girlwithsunglassescontentpara"
                style={{
                  textAlign: "center",
                  margin: "0px 20px",

                  color: "white",
                }}
              >
              

WhoosiT is a tool for visitor management for offices, workplaces, and manufacturing units, harnessing QR technology and video calls to ensure controlled access, efficient operations, and a secure and efficient environment for staff, management, and visitors. 
              </p>
              <div className="girlwithsunglassesviewmorebutton">
                <div className="girlwithsunglassesviewmore">
                  {" "}
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopOfficeClick}>
                      View More <ArrowRightAltIcon />
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>

          <div className="shotmanslide">
            <img src={shotman} alt="carpark" className="shotmanslideimg" />
            <div className="shotmancontent">
              <p className="shotmanHead">Home</p>
              <div className="ourusecasewhitelinemark">
                <div className="ourusecasewhiteline"></div>
              </div>
              <p
                className="shotmancontentpara"
                style={{
                  textAlign: "center",
                  margin: "0px 20px",

                  color: "white",
                }}
              >
                WhoosiT offers a robust solution for vehicle identification and
                communication, leveraging QR technology and video calling to
                streamline parking management and enhance security measures for
                vehicle owners and parking authorities alike.
              </p>
              <div className="shotmanviewmorebutton">
                <div className="shotmanviewmore">
                  {" "}
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopHomeClick}>
                      View More <ArrowRightAltIcon />
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>

          <div className="industrialparkslide">
            <img
              src={industrialpark}
              alt="carpark"
              className="industrialparkslideimg"
            />
            <div className="industrialparkcontent">
              <p className="industrialparkHead">Society</p>
              <div className="ourusecasewhitelinemark">
                <div className="ourusecasewhiteline"></div>
              </div>
              <p
                className="industrialparkcontentpara"
                style={{
                  textAlign: "center",
                  margin: "0px 20px",

                  color: "white",
                }}
              >
                   WhoosiT offers a robust solution for vehicle identification and
                communication, leveraging QR technology and video calling to
                streamline parking management and enhance security measures for
                vehicle owners and parking authorities alike.
             
              </p>
              <div className="industrialparkviewmorebutton">
                <div className="industrialparkviewmore">
                  {" "}
                  <React.Fragment>
                    <Button
                      variant="none"
                      onClick={handledesktopManufacturingUnitsClick}
                    >
                      View More <ArrowRightAltIcon />
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>

          <div className="buildingslide">
            <img src={building} alt="carpark" className="buildingslideimg" />
            <div className="buildingcontent">
              <p className="buildingHead">Lost & Found</p>
              <div className="ourusecasewhitelinemark">
                <div className="ourusecasewhiteline"></div>
              </div>
              <p
                className="buildingcontentpara"
                style={{
                  textAlign: "center",
                  margin: "0px 20px",

                  color: "white",
                }}
              >
               WhoosiT is a comprehensive tool for a seamless and proficient process for handling lost and found items by leveraging video calling through QR scanning technology, ensuring that lost items are returned to their legitimate owners expediently and accurately and safeguarding an individual’s personal belongings. 
              </p>
              <div className="buildingviewmorebutton">
                <div className="buildingviewmore">
                  {" "}
                  <React.Fragment>
                    <Button variant="none" onClick={handledesktopSocietyClick}>
                      View More <ArrowRightAltIcon />
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
};

export default UseCases;



