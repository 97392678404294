import React, { useEffect, useState } from "react";
import styled from "styled-components";

const scrollingUp = process.env.PUBLIC_URL + "/images/scroll to top-02@2x.png";
const StyledScrollUp = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  right: 30px;
  top: 75%;
  height: auto;
  z-index: 99;
  cursor: pointer;
`;

const StyledStickeyImage = styled("img")`
  width: 100%;
  margin: 2px;
  height: auto;
`;

const Flotingicon = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  return (
    <>
      <StyledScrollUp>
        {showButton && (
          <StyledStickeyImage onClick={scrollToTop} src={scrollingUp} /> 
        )}
      </StyledScrollUp>
    </>
  );
};

export default Flotingicon;
