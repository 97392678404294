import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import Home from "./components/home/home";
import Plan from "./components/Plan/plan";
import Privacypolicy from "./components/privacypolicy";
import Termspolicy from "./components/termsAndCondition";
import Faq from "./components/faq/faq";


function App() {


  useEffect(()=>{
    const os = ['Windows', 'Linux', 'Mac']; // add your OS values
    console.log(os.find(v=>navigator.appVersion.indexOf(v) >= 0));
  },[])    

  return (
    <>
      <>
        <BrowserRouter>
          <Routes>
          {/* //website={showWhoosit} */}
            <Route path="/" element={<Home />} /> 
            <Route path="/plan" element={<Plan />} />
            <Route path="/privacy" element={<Privacypolicy/>}/>
            <Route path="/terms" element={<Termspolicy/>}/>
            <Route path="/faq" element={<div style={{width:"100vw", height:"100vh", backgroundColor:"#181a1f"}}><Faq/></div>}/>
          </Routes>
        </BrowserRouter>
      </>
    </>
  );
}

export default App;
