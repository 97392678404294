import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import "./faq.css";

const CenteredContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#181a1f",
  paddingTop: "70px",
  paddingBottom: "80px",
  color: "white",
  marginBottom: "4%",

  width: "100%",
  height: "auto",
});

const StyledAccordion = styled(Accordion)(({ theme, isExpanded }) => ({
  backgroundColor: "white",
  color: "black",
  marginBottom: theme.spacing(1),
  width: "70%",
  marginLeft: isExpanded ? "auto" : 0,
  transition: "margin-left 0.3s", 
}));

const CenteredHeading = styled("div")({
  textAlign: "center",
  color: "white",
  marginBottom: "10px",
  fontSize: "24px",
});

const Faq = ({ targetDivRef }) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <CenteredContainer
      ref={targetDivRef}
      id="frequentlyaskedquestion"
      className="FaqSection"
    >
      <CenteredHeading className="faqHeadingCenter">
        <div className="faqheading">Frequently Asked Questions</div>
      </CenteredHeading>
      <div className="underlinestyled"></div>
      <StyledAccordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel1" ? (
              <RemoveIcon
                style={{ color: expanded === "panel1" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ color: expanded === "panel1" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            What is WhoosiT and what does it offer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            WhoosiT is a versatile app that uses QR codes to identify and
            communicate with visitors across diverse settings such as
            residential areas, hotels, offices, manufacturing units, and
            vehicles. It provides a seamless security gateway through video
            calling, facilitating secure interactions with visitors.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel2" ? (
              <RemoveIcon
                style={{ color: expanded === "panel2" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ color: expanded === "panel2" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            How does the QR scanning feature work in WhoosiT?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The QR scanning feature allows for swift visitor identification by
            scanning unique codes assigned to visitors or vehicles, granting
            controlled access, and enhancing security measures.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel3" ? (
              <RemoveIcon
                style={{ color: expanded === "panel3" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel3a-content"
          id="panel3a-header"
          style={{ color: expanded === "panel3" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            Can I communicate with visitors using WhoosiT?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, the app facilitates direct communication with visitors through
            video calling, enabling residents, staff, or management to visually
            confirm identities and communicate effectively.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel4" ? (
              <RemoveIcon
                style={{ color: expanded === "panel4" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel4a-content"
          id="panel4a-header"
          style={{ color: expanded === "panel4" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            Can I add multiple addresses or locations to my WhoosiT account?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Absolutely, WhoosiT allows users to add multiple addresses, making
            it convenient for managing and securing various locations such as
            homes, offices, or even different manufacturing units.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel5" ? (
              <RemoveIcon
                style={{ color: expanded === "panel5" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel5a-content"
          id="panel5a-header"
          style={{ color: expanded === "panel5" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            Is it possible to add multiple members or users to my WhoosiT
            account?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, the app supports adding multiple members or users to an
            account, ensuring that different individuals within a household,
            office, or community can access and utilize its features.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel6" ? (
              <RemoveIcon
                style={{ color: expanded === "panel6" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel6a-content"
          id="panel6a-header"
          style={{ color: expanded === "panel6" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            Can I chat while on a video call using WhoosiT?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {" "}
            Certainly, the app includes a chat feature that allows users to
            communicate via text while on a video call, enhancing the overall
            communication experience.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel7" ? (
              <RemoveIcon
                style={{ color: expanded === "panel7" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel7a-content"
          id="panel7a-header"
          style={{ color: expanded === "panel7" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            {" "}
            Does WhoosiT support ringing on multiple devices simultaneously?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, the app facilitates ringing on multiple devices simultaneously,
            ensuring that users do not miss any visitor calls or notifications.{" "}
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel8" ? (
              <RemoveIcon
                style={{ color: expanded === "panel8" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel8a-content"
          id="panel8a-header"
          style={{ color: expanded === "panel8" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            Can WhoosiT support conference video calls?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {" "}
            Absolutely, the app offers conference video call capabilities,
            allowing multiple users to join a video call simultaneously, making
            it suitable for meetings or discussions involving several parties.{" "}
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel9" ? (
              <RemoveIcon
                style={{ color: expanded === "panel9" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel9a-content"
          id="panel9a-header"
          style={{ color: expanded === "panel9" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            How does WhoosiT enhance security in residential areas or societies?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {" "}
            WhoosiT provides a robust security gateway by allowing visual
            verification of visitors through video calling, ensuring controlled
            access and improved security measures within residential areas or
            communities.{" "}
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary
          expandIcon={
            expanded === "panel10" ? (
              <RemoveIcon
                style={{ color: expanded === "panel10" ? "#FF6C2C" : "black" }}
              />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel10a-content"
          id="panel10a-header"
          style={{ color: expanded === "panel10" ? "#FF6C2C" : "black" }}
        >
          <Typography fontSize="18px" fontWeight="bold">
            {" "}
            In what ways does WhoosiT benefit businesses like hotels, offices,
            or manufacturing units?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {" "}
            The app streamlines visitor management, facilitates efficient
            communication, and offers enhanced security features, making it
            invaluable for businesses to manage guest interactions, deliveries,
            and overall security protocols.
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </CenteredContainer>
  );
};

export default Faq;
