import React from "react";
import { useState } from "react";
import "./contact.css";
import ReCAPTCHA from "react-google-recaptcha";
const homeqr = process.env.PUBLIC_URL + "/images/Website Images/ContactUs.png";

const linkedin = process.env.PUBLIC_URL + "/images/linkedin-in@2x.png";
const twitter = process.env.PUBLIC_URL + "/images/twitter@2x.png";
const instagram = process.env.PUBLIC_URL + "/images/Path 146@2x.png";

const Contact = () => {
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorContact, setErrorContact] = useState("");
  const [errorComment, setErrorComment] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  

  const validateName = (value) => {
    const nameRegex = /^[a-zA-Z]*$/;
    if (!value.trim()) {
      setErrorName("Name is required");
    }
    else if (!nameRegex.test(value)) {
      setErrorName("Invalid Name");
    }
     else {
      setErrorName("");
    }
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value.trim()) {
      setErrorEmail("Email is required");
    } else if (!emailRegex.test(value)) {
      setErrorEmail("Invalid email address");
    } else {
      setErrorEmail("");
    }
  };

  const validateContact = (value) => {
    const contactRegex = /^\d{10}$/;
    if (!value.trim()) {
      setErrorContact("Contact number is required");
    } else if (!contactRegex.test(value)) {
      setErrorContact("Invalid contact number");
    } else {
      setErrorContact("");
    }
  };

  const validateComment=(value)=>{
    if(!value){
      setErrorComment("message feild is required");
    }
    else {
      setErrorComment("");
    }

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (!name || !email || !contact || !comment || !recaptchaToken) {
   setMessage("please fill the required feild and verify the recaptcha")
    } else {
    
      setMessage("verified captcha");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        name: name,
        email: email,
        mobile: contact,
        message: comment,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://whoosit.in/doorstep/website/query", requestOptions)
        .then((response) => response.text())
        .then((result) => {
         console.log("query sent successfully")
        })
        .catch((error) => {
          console.log("Something went wrong.");
        });

      alert("Form submission successful!");
      
      setName("");
      setEmail("");
      setContact("");
      setComment("");
      setRecaptchaToken("");

    }
  };


  
  
  const handleComment = (e) => {
    const value = e.target.value;
    setComment(e.target.value);
    validateComment(value)
  };

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
    validateName(value);
  };

  const handleEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const handleContact = (e) => {
    const value = e.target.value;
    setContact(value);
    validateContact(value);
  };



  const handleRecaptchaCode = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div className="contactSection">
      <div className="contactform">
        <div className="contactheading">
          <span>Contact Us</span>
        </div>
        <div className="contactblueunderline"></div>
        <br />
        <br />

        <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={handleName}
          className={`inputfeild ${errorName ? "error" : ""}`}
        />
        {errorName && <p className="error-message">{errorName}</p>}
        <br />
        <br />
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={handleEmail}
          className={`inputfeild ${errorEmail ? "error" : ""}`}
        />
        {errorEmail && <p className="error-message">{errorEmail}</p>}
        <br />
        <br />
        <input
          type="tel"
          placeholder="Contact Number"
          value={contact}
          onChange={handleContact}
          className={`inputfeild ${errorContact ? "error" : ""}`}
        />
        {errorContact && <p className="error-message">{errorContact}</p>}
        <br />
        <br />
        <textarea
          placeholder="Write Your Message Here..."
          value={comment}
          onChange={handleComment}
          className={`inputfeild ${errorComment ? "error" : ""}`}
        />
        {errorComment && (
            <p className="error-message">{errorComment}</p>
          )}
          <br />
        <br />
        <ReCAPTCHA
          sitekey="6LdSHWkpAAAAADSo57geuSKMWxk7jYcH9KDmwVUc"
            h1="EN"
            size="normal"
          onChange={handleRecaptchaCode}
        /><br />
       


             <button
            type="submit"
        
            className="inputfeildbutton"
            style={{ cursor: "pointer" }}
          >SUBMIT</button>
        {message && <p className="message" style={{color:"red" ,margin: 0}}>{message}</p>}
      </form>
        <br />
        <br />

        <div className="contactdetail">
          <span className="contactdetails">
            <a
              href={
                "https://mail.google.com/mail/?view=cm&fs=1&to=contact@whoosit.in"
              }
              style={{ color: "white", textDecoration: "none" }}
            >
              contact@whoosit.in
            </a>
          </span>
          &nbsp;&nbsp;
          <span className="contactdetails contactdetailsline">|</span>{" "}
          &nbsp;&nbsp;
          <span className="contactdetails">
            <a
              href="tel:+91-8824693997"
              style={{ color: "white", textDecoration: "none" }}
            >
              +91-8824693997
            </a>
          </span>
        </div>
        <br />
        <br />
        <div className="socialmedia">
          
          <a href="https://www.linkedin.com/login">
            <img
              src={linkedin}
              style={{ width: "20px", height: "21px" }}
              alt="linkedin"
            ></img>
          </a>
          <a href="https://twitter.com/i/flow/login">
            <img
              src={twitter}
              style={{ width: "20px", height: "21px" }}
              alt="twitter"
            ></img>
          </a>
          <a href="https://www.instagram.com/accounts/login/?hl=en">
            <img
              src={instagram}
              style={{ width: "20px", height: "21px" }}
              alt="instagram"
            ></img>
          </a>
        </div>
      </div>

      <div className="contactimage">
        <img src={homeqr} alt="houseqr" className="contactusimage" />
      </div>
    </div>
  );
};

export default Contact;
