import Header from "../header/header";
import Banner from "../banner/banner";
import Contact from "../contactus/contact";
import Footer from "../footer/footer";
import Faq from "../faq/faq";
import MultiActionAreaCard from "../cards/cards";
import VideoCall from "../videoCalling/works";
import MultiItemCarousel from "../features/features";
import AboutUsSection from "../aboutus/about";
import UseCases from "../useCases/useCases";
import Sticky from "../stickydiv/sticky";
import "../home/home.css";
import Flotingicon from "../scrollBottomToTop/bottomToTop";
import { useEffect } from "react";

function Home() {
  useEffect(() => {
    //for rendering at the top of the page
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="home">
        {/* <Sticky /> */}
        <Flotingicon />
        <Header isUnlockButton={true}/>

        <Banner />

        <MultiActionAreaCard />

        <AboutUsSection />

        <UseCases />

        <VideoCall />
     
        <MultiItemCarousel />
      
        <Faq />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default Home;
